import React from 'react';
import { PageProps } from "gatsby";
import Layout from '../../components/layout/layout';

const previewImage = {imageFile: 'home.png', imageWidth: 1200, imageHeight: 630, imageDescription: 'Screenshot of the website'};

export default function PageContent(props: PageProps) {
  let language = 'en';
  return (
    <Layout language={language} title="About me" description="Some more things about myself" path={props.path} previewimage={previewImage} hasLanguageCounterpart={false}>
      I’m a professional Software Developer from Germany, working with the .net Framework and MSSQL as major technology stack since 2005. I have developed User Interfaces, (Web-)Sevices, Command Line Tools and Component Libraries for an international Semiconductor Company until the middle of 2017.

Additional I always worked with standard web technologies based on the XAMP Stack in my free time (including Frontend Development like HTML, CSS and JavaScript).

Besides developing software I like to play around with all kinds of creative tools and technology, for example my DSLR, image or video editing software, 3D modelling and animation or Z-WAVE smart home devices controlled by a Raspberry PI.

Over the past 15 to 20 years I have gained knowledge in various fields, mainly in setup, operation and maintenance of software, hardware or commonly used services.

Have a look around here if you want to know more…
    </Layout>
  );
}

